<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Sales" title="P60  Daily Trend" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-9 col-md-10">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Date From"
                      mask="date"
                      v-model="dateFrom"
                      :value="dateFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectFromDate"
                      tabindex="1"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Date To"
                      mask="date"
                      v-model="dateTo"
                      :value="dateTo"
                      :rules="[VALIDATION.required]"
                      @input="onSelectToDate"
                      tabindex="2"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-3">
                    <SelectStores
                      v-model="stores"
                      :values="stores"
                      @updateStore="$event => (stores = $event)"
                      @getOptions="onSetDefaultStores"
                      tabindex="3"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormSelect
                      label="Level"
                      v-model="level"
                      :values="level"
                      :options="levelOptions"
                      :rules="[VALIDATION.required]"
                      tabindex="4"
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-6 col-md-3"
                    v-if="level === 'category'"
                  >
                    <SelectCategories
                      v-model="category"
                      :values="category"
                      @updateStore="$event => (category = $event)"
                      tabindex="5"
                      multiple
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-6 col-md-3"
                    v-if="level === 'brand'"
                  >
                    <SelectBrands
                      v-model="brand"
                      :values="brand"
                      @updateStore="$event => (brand = $event)"
                      tabindex="6"
                      multiple
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-12 col-md-12"
                    v-if="level === 'sku'"
                  >
                    <SelectProducts
                      class="max-w-xl mb-2"
                      v-model="sku"
                      :values="sku"
                      :rules="[]"
                      tabindex="7"
                      rawData
                      @updateStore="onSelectSKU"
                    />
                    <Tag
                      variant="default"
                      @remove="onRemoveSKU(tag.value)"
                      v-for="tag in skus"
                      :key="tag?.value"
                      :text="`${tag?.value} - ${tag?.label}`"
                      removable
                      tooltip
                    />
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-3 col-md-2 text-right">
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="8"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="row items-start q-col-gutter-md" v-if="hasData">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <SimpleCard header="Daily Sales Trend">
            <LineChart id="linechart" axis="xy" :data="salesData" />
          </SimpleCard>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">P60 Daily Trend</h5>
    <p>Generate daily trend by date range, store, category, brand or SKU</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Date From:</strong> required field</p>
    <p><strong>Date To:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
    <p><strong>Level:</strong> required field</p>
    <p><strong>Category:</strong> required field</p>
    <p><strong>Brand:</strong> required field</p>
    <p><strong>SKU:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, inject, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, NoDataCard, SimpleCard } from '@/components/cards'
import { FormDate, FormSelect } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { LineChart } from '@/components/charts'
import Loader from '@/components/Loader'
import Tag from '@/components/Tag'
import {
  SelectStores,
  SelectCategories,
  SelectBrands,
  SelectProducts
} from '@/components/customs'

import { optionsLevel } from '@/constants'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  getIconUrl,
  fetchData
} from '@/tools'

export default {
  name: 'P60DailyTrend',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    SimpleCard,
    FormDate,
    FormSelect,
    Button,
    Table,
    Modal,
    LineChart,
    Loader,
    Tag,
    SelectStores,
    SelectCategories,
    SelectBrands,
    SelectProducts
  },
  setup() {
    const { showToast } = Toast()
    const state = inject('store')?.state

    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const isRetailer = ref(state?.accountType?.retailer)
    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const filterForm = ref(null)
    const dateFrom = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const dateTo = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyLastDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const stores = ref([])
    const category = ref(null)
    const brand = ref(null)
    const sku = ref(null)
    const skus = ref([])
    const level = ref(null)
    const levelOptions = ref(optionsLevel)
    const salesData = ref([])
    const salesTableData = ref(null)
    const salesTableHeader = ref([
      {
        name: 'day',
        label: 'Day',
        field: 'day',
        align: 'center',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Sales',
        field: 'sales',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (level.value === 'sku' && skus.value.length === 0) {
          showToast('Please select at least 1 SKU', 'info')
        } else {
          if (success) {
            const payload = {
              pagination: {
                page: pageNo.value,
                per_page: limitPage.value
              },
              filters: {
                date_from: DATE.toFriendlyDate(dateFrom.value),
                date_to: DATE.toFriendlyDate(dateTo.value),
                stores: stores.value,
                level: level.value
              }
            }

            if (level.value === 'category') {
              payload.filters.values = category.value
            }

            if (level.value === 'brand') {
              payload.filters.values = brand.value
            }

            if (level.value === 'sku') {
              payload.filters.values = skus.value.map(item => item.value)
            }

            if (isRetailer.value) {
              payload.filters.principal = state?.defaultID

              postSales('/v1/retailer/product/p60-daily-trend', payload)
            } else {
              payload.filters.retailer = state?.defaultID

              postSales('/v1/product/p60-daily-trend', payload)
            }
          } else {
            showToast('Please fill all the required fields', 'info')
          }
        }
      })
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      let linechartImg

      FORMAT.svg2img('linechart', 1000, 400, function (pngData) {
        linechartImg = pngData
      })

      setTimeout(() => {
        if (linechartImg) {
          const initialPosition = 25
          const lineChartPosition = salesData.value.length

          EXPORT.exportToExcel({
            header: salesTableHeader.value,
            data: salesTableData.value,
            filename: 'p60_daily_trend_report',
            chart: linechartImg,
            chartName: 'Daily Sales Trend',
            position: {
              table: initialPosition,
              chart: {
                col: lineChartPosition / 2,
                row: initialPosition
              }
            }
          })
        }
      }, 500)
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    const onRemoveSKU = data => {
      skus.value = skus.value.filter(item => item.value !== data)
    }

    const onSelectSKU = data => {
      if (data) {
        const exists = Object.values(skus.value).includes(data)

        if (!exists) {
          skus.value.push(data)
        }
      }
      sku.value = null
    }

    const onSetDefaultStores = data => {
      stores.value = data.map(item => item.value)
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            totalCount.value = dataSales?.value?.length

            salesTableData.value = dataSales?.value?.map(item => ({
              day: DATE.toFriendlyDate(item?.date),
              sales: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))

            salesData.value = dataSales?.value?.map(item => ({
              name: DATE.toFriendlyDate(item?.date),
              value: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      showModal,
      loadingSales,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      dateFrom,
      dateTo,
      stores,
      category,
      brand,
      sku,
      skus,
      level,
      levelOptions,
      salesData,
      salesTableHeader,
      salesTableData,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onSelectFromDate,
      onSelectToDate,
      exportTable,
      onRequest,
      onRemoveSKU,
      onSelectSKU,
      onSetDefaultStores
    }
  }
}
</script>
