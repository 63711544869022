<template>
  <P60DailyTrend />
</template>

<script>
import P60DailyTrend from '@/components/pages/sales/P60DailyTrend'

export default {
  components: {
    P60DailyTrend
  }
}
</script>
